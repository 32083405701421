const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Sobre Mim",
    url: "/about",
  },
  {
    label: "Habilidades",
    url: "/skills"
  }
]

export default links
