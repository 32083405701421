const links = [
  {
    label: "Github",
    url: "https://github.com/MariaLisboa1",
  },
  {
    label: "Twitter",
    url: "https://twitter.com/mnlisboa_",
  },
  {
    label: "Instagram",
    url: "https://www.instagram.com/nalisb",
  },
  {
    label: "Linkedin",
    url: "https://www.linkedin.com/in/maria-lisboa-b5b221137/",
  },
]

export default links
