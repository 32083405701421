import React from "react"

import links from "./content"
import getThemeColor from '../../utils/getThemeColor'
import * as S from "./styled"

const MenuLinks = () => (
  <S.MenuLinksWrapper>
    <S.MenuLinksList>
      {links.map((link, i) => (
        <S.MenuLinkItem key={i}>
          <S.MenuLinksLink 
              cover 
              direction="left" 
              bg={getThemeColor()}
              duration={0.6} 
              to={link.url} 
              activeClassName="active"
            >
            {link.label}
          </S.MenuLinksLink>
        </S.MenuLinkItem>
      ))}
    </S.MenuLinksList>
  </S.MenuLinksWrapper>
)

export default MenuLinks
